.additional-service-table .ant-table-thead .ant-table-cell {
    background-color: var(--veg-light-blue);
    font-weight: 600;
    white-space: pre;
}

.additional-service-table .ant-table-thead .ant-table-cell,
.additional-service-table .ant-table-tbody .ant-table-cell {
    padding: 8px 16px;
}

.additional-service-table .ant-table-row-level-0 {
    background-color: var(--gray-2);
    font-weight: 600;
    white-space: pre;
}
.additional-service-table .ant-table-selection-column {
    min-width: 2em;
    padding: 0;
}

.additional-service-table td:last-child {
    min-width: 8.5em;
}

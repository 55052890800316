.rc-virtual-list-holder-inner .ant-select-item-option-active:not(.ant-select-item-option-disabled):has(.search-result) {
	background-color: white; 
	cursor: default;
}

.rc-virtual-list-holder-inner .ant-select-item-option:has(.search-result) {
	padding: 0px;
}

.ant-select-item-empty:has(.search-result) {
	padding: 0px;
}

.search-result {
	padding-right: 24px;
	padding-left: 24px;
}
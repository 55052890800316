.__fade-transition-mixin {
  transition: all linear 250ms;
}
.button:global(.ant-btn-primary) {
  background: #1890ff;
  border-color: #1890ff;
}
.button:globa(.ant-btn-background-ghost) {
  color: #1890ff;
  border-color: #1890ff;
}

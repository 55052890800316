.collapse-content__wrapper {
	max-width: fit-content;
	padding: 16px 32px 16px 0;
	cursor: pointer;
}

.collapse-content__title {
	margin-left: 8px;
	font-size: 18px;
	font-weight: var(--font-medium);
	color: var(--gray-9);
}

.collapse-content--expanded {
	margin-bottom: 12px;
}

.visitsTitle {
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 38px;
    padding-left: 24px;
    padding-top: 39px;
}

.visitsTable {
    padding-left: 26px;
    padding-right: 26px;
    padding-top: 22px;
}

.row {
	cursor: pointer;
}

.visitsTable :global .ant-table-column-sorters {
    display: flex;
    justify-content: space-between;
    padding: 16px 0 16px 16px;
}

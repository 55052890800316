.billing-table .ant-table-thead .ant-table-cell {
    background-color: var(--veg-light-blue);
    font-weight: 600;
    white-space: pre;
}

.billing-table .ant-table-thead .ant-table-cell,
.billing-table .ant-table-tbody .ant-table-cell {
    padding: 8px 16px;
}

.billing-table .ant-table-row-level-0 {
    background-color: var(--gray-2);
    font-weight: 600;
    white-space: pre;
}
.billing-table .ant-table-selection-column {
    min-width: 2em;
    padding: 0;
}

.billing-table td:last-child {
    min-width: 8.5em;
}

.billing-table .stripe-payment-error-tag {
    font-weight: normal;
    color: var(--red-6);
    background-color: var(--red-1);
    border-color: var(--red-3);
}

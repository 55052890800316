/* Global variables */
:root {
    /* Colors */
    --gold-1: #fffbe6;
    --gold-3: #ffe58f;
    --gold-6: #faad14;
    --gray-2: #fafafa;
    --gray-3: #f5f5f5;
    --gray-4: #f0f0f0;
    --gray-5: #d9d9d9;
    --gray-6: #bfbfbf;
    --gray-7: #8c8c8c;
    --gray-8: #595959;
    --gray-9: #262626;
    --green-1: #f6ffed;
    --green-3: #b7eb8f;
    --green-6: #52c41a;
    --green-alert-success: #73c64c;
    --orange-1: #fff7e6;
    --orange-3: #ffd591;
    --orange-6: #fa8c16;
    --purple-1: #f9f0ff;
    --purple-3: #d3adf7;
    --purple-6: #722ed1;
    --red-1: #fff1f0;
    --red-3: #ffa39e;
    --red-5: #ff4d4f;
    --red-6: #f5222d;
    --volcano-1: #fff2e8;
    --volcano-3: #ffbb96;
    --volcano-6: #fa541c;
    --blue-1: #e6f7ff;
    --blue-3: #91d5ff;
    --antd-blue: #1890ff;
    --geek-blue-1: #f0f5ff;
    --geek-blue-3: #adc6ff;
    --geek-blue-6: #2f54eb;
    --veg-red: #e7202e;
    --veg-secondary-red: #ba141a;
    --veg-blue: #143980;
    --veg-light-blue: #f0f5f7;
    --veg-tint-blue: #4878bb;
    --veg-secondary-blue: #1e387b;
    --veg-tint-plum: #bb8bb3;
    --veg-secondary-plum: #8a1e61;
    --veg-tint-lime: #dfe67d;
    --veg-secondary-lime: #b2d235;
    --veg-tint-teal: #76cfe7;
    --veg-secondary-teal: #009db9;
    --veg-tint-gold: #ffcd7e;
    --veg-secondary-gold: #fbad18;
    --veg-green: #26a416;
    --veg-green-gray: #b6c3b5;

    /* Fonts configs */
    --font-regular: 400;
    --font-medium: 500;
    --font-semi-bold: 600;
    --font-bold: 700;

    /* Fonts sizes */
    --h1-size: 20px;

    /* Spacing */
    --spacing-xs: 4px;
    --spacing-sm: 8px;
    --spacing-md: 16px;
    --spacing-lg: 24px;
    --spacing-xl: 40px;
}

@font-face {
    font-family: 'Zona Pro';
    font-weight: 400;
    src: local('Zona Pro'), url(./assets/fonts/zonaPro.woff2) format('woff2');
}

@font-face {
    font-family: 'Zona Pro Bold';
    font-weight: 700;
    src: local('Zona Pro Bold'), url(./assets/fonts/zonaProBold.woff2) format('woff2');
}

* {
    /* So 100% means 100% */
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
}

body {
    margin: 0;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.App,
body,
#root {
    min-height: 100%;
    background: #f0f2f5; /*Ant default */
}

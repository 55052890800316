.visit-status-tag {
    border-radius: 2px;
    border: 1px solid;
    line-height: 20px;
    padding: 0 var(--spacing-sm);
    text-transform: uppercase;
}

.doctor-review-tag {
    background: var(--volcano-1);
    border-color: var(--volcano-3);
    color: var(--volcano-6);
}

.estimate-status-tag--pending {
    background: var(--purple-1);
    border-color: var(--purple-3);
    color: var(--purple-6);
}

.estimate-status-tag--draft {
    background: var(--volcano-1);
    border-color: var(--volcano-3);
    color: var(--volcano-6);
}

.estimate-status-tag--bal_due {
    background: var(--orange-1);
    border-color: var(--orange-3);
    color: var(--orange-6);
}

.estimate-status-tag--nc {
    background: var(--red-1);
    border-color: var(--red-3);
    color: var(--red-6);
}

.overview-order-table {
    overflow-y: auto;
}

.overview-order-table  .ant-table-title {
    background: #FFF !important;
}

.overview-order-table  .ant-table-thead .ant-table-cell {
    font-weight: bold;
}
.overview-order-table   td.ant-table-column-sort {
    background-color: transparent;
}
.overview-card-content-header {
    font-size: 16px;
    font-weight: var(--font-medium);
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
}
.overview-card-title {
    font-size: 18px;
    font-weight: var(--font-medium);
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
    
}

.overview-card .ant-card-extra,
.overview-tab__latest-notes .notes-file-section,
.overview-tab__latest-notes .physical-exam-content {
    padding: 0;
}

.overview-tab__latest-notes .ant-card,
.overview-tab__latest-notes .ant-card-head,
.overview-tab__latest-notes .ant-card-extra button {
    border: none;
}

.overview-tab__latest-notes .styled-avatar {
    display: none;
}

.overview-card-height .ant-card-body {
    max-height: 250px !important;
    min-height: 250px !important;
    overflow-y: scroll;
}
@media (max-width: 768px) {
    .overview-card-height .ant-card-body {
        min-height: 250px !important;
        max-height: 600px !important;
    }
  }
.overview-order-table-row {
    cursor: pointer;
}

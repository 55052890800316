.__fade-transition-mixin {
	transition: all linear 250ms;
}
.__sidebar-animation-mixin {
	transition: all 300ms linear;
}
ul {
	padding-inline-start: 15px;
}
.clickable-text,
.new-vitals,
.added-by-name,
.edit-sidebar-text {
	color: #1890ff;
}
.clickable-text:hover,
.new-vitals:hover,
.added-by-name:hover,
.edit-sidebar-text:hover {
	cursor: pointer;
}
.added-by-container {
	color: #969696;
}
.sidebar-pet-species {
	margin-bottom: 0;
}
.sidebar-pet-name {
	display: initial;
	text-transform: capitalize;
	font-size: 30px;
	font-weight: var(--font-medium);
	line-height: 1;
}
.sidebar-doctor-container {
	margin-top: 18px;
}
.sidebar-doctor-name {
	text-transform: capitalize;
	font-size: 16px;
}
.sidebar-doctor-name.current-doctor-assigned {
	font-weight: bold;
}
.sidebar-no-doctor {
	font-style: italic;
	font-size: 16px;
}
.sidebar-vital-name, .sidebar-visit-details,
.overview-vital-name {
	text-transform: capitalize;
	color: var(--gray-9);
}
.sidebar-vital-container {
	margin-top: 2px;
	margin-bottom: 8px;
}
.sidebar-profile-link {
	margin-top: 6px;
}
.sidebar-problem-list {
	max-height: 200px;
	width: 100%;
	overflow: auto;
}
.sidebar-owner-name {
	text-transform: capitalize;
	font-weight: var(--font-medium);
}

.edit-sidebar-icon, .more-sidebar-icon {
	white-space: pre-wrap;
  }
.more-sidebar-icon:hover {
	background: #f5f5f5;
	cursor: pointer;
}
.edit-sidebar-icon:hover,
.title.clickable:hover {
	opacity: 0.6;
	cursor: pointer;
}
.owner-search-popover,
.edit-owner-popover,
.rdvm-info-popover,
.add-multi-complaint-popover {
	width: 500px;
}
.reason-for-visit-tag {
	white-space: initial;
	font-size: 16px;
	font-weight: var(--font-medium);
	line-height: 24px;
	color: var(--gray-8);
}
.visit-wrapper {
	display: flex;
}
.visit-header {
	padding: 16px 24px 0;
	background: #fdfdfd;
	overflow: hidden;
}
.visit-wrapper .visit-sidebar {
	border-right: 1px solid var(--gray-5);
	background-color: #f9f9f9;
	width: 25%;
	min-width: 25%;
	margin-left: -23%;
	transition: margin-left 0.3s linear;
}

.visit-wrapper.sidebar-open .visit-sidebar {
	border-width: 1px;
	/* safari doesn't do the transition
	if you just set this to 0 without the percentage */
	margin-left: 0%;
}

.visit-wrapper .visit-sidebar-toggle-button {
	position: absolute;
	display: block;
	width: 24px;
	height: 24px;
	min-width: auto;
	right: -12px;
	top: 30px;
	z-index: 10;
	background: #fff;
	border: 0;
	padding: 0;
	transition: transform 0.3s linear;
}
.visit-wrapper .visit-sidebar-toggle-button .anticon {
	color: var(--gray-7);
	font-size: 24px;
    height: 24px;
}

.visit-sidebar-wrapper::after {
	position: absolute;
	content: "";
	width: 100%;
	height: calc(100vh - 198px);
	left: 0;
	top: 0;
	background-color: var(--gray-4);
	transition: opacity 0.3s linear;
	pointer-events: none;
}
.visit-wrapper.sidebar-open .visit-sidebar-wrapper::after {
	opacity: 0;
}

.visit-wrapper.sidebar-open .visit-sidebar-toggle-button {
	transform: rotate(180deg);
}

.visit-wrapper .visit-main-content {
	width: 100%;
}

.visit-wrapper .visit-main-content .ant-tabs-tabpane,
.visit-wrapper .visit-sidebar-scrollable-content {
	height: calc(100vh - 198px);
	overflow: auto;
	padding: var(--spacing-lg);
}

.visit-discharge-alert ~ .visit-wrapper .visit-main-content .ant-tabs-tabpane, 
.visit-discharge-alert ~ .visit-wrapper .visit-sidebar-scrollable-content {
	height: calc(100vh - 246px);
}

.closed-visit-message ~ .visit-wrapper .visit-main-content .ant-tabs-tabpane,
.closed-visit-message ~ .visit-wrapper .visit-sidebar-scrollable-content {
	height: calc(100vh - 238px);
}

.visit-wrapper .visit-tabs {
    background: #fff;
}
.visit-wrapper .visit-tabs .ant-tabs-nav {
	background: #fdfdfd;
	padding-right: 24px;
	margin: 0;
}
.visit-wrapper .visit-tabs .ant-tabs-extra-content {
	padding-bottom: 4px;
}
.visit-wrapper .visit-tabs .ant-tabs-nav .ant-tabs-tab {
	padding: 8px 0;
}
.visit-wrapper .visit-tabs .ant-tabs-nav .ant-tabs-tab:hover {
	color: inherit;
}
#visit-tabs > .ant-tabs-nav .ant-tabs-tab:first-child {
	display: none;
}
#visit-tabs > .ant-tabs-nav .ant-tabs-tab:nth-child(2) {
	margin-left: 24px;
}
.visit-wrapper .visit-main-content .ant-tabs-tabpane {
	transition: 0.3s linear;
	width: 98%;
}
.visit-wrapper.sidebar-open .visit-main-content .ant-tabs-tabpane {
	width: 75%;
}
.visit-wrapper .ant-tabs-ink-bar {
	background: var(--veg-red);
}
.visit-wrapper .ant-tabs-tab .ant-tabs-tab-btn:focus,
.visit-wrapper .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
	color: inherit;
	font-weight: var(--font-bold);
	text-shadow:none;
}
.visit-wrapper .ant-tabs-tab .ant-tabs-tab-btn::before {
	display: block;
	font-size: 14px;
    content: "";
    font-weight: var(--font-semi-bold);
	visibility: hidden;
	overflow: hidden;
	height: 0;
}
#visit-tabs-tab-mission_control::before {
	content: "Overview";
}
#visit-tabs-tab-progress_notes::before {
	content: "Notes";
}
#visit-tabs-tab-documents::before {
	content: "Documents";
}
#visit-tabs-tab-timeline::before {
	content: "Timeline";
}
#visit-tabs-tab-treatment_sheet::before {
	content: "Treatment Sheet";
}
#visit-tabs-tab-discharge::before {
	content: "Discharge";
}
#visit-tabs-tab-billing::before {
	content: "Billing";
}
/**
* The `Markdown` rendering library will output normal <p> tags, which have JUMBO margins
*/
.visit-sidebar p {
	margin-bottom: 0;
}
.visit-sidebar .ant-divider-horizontal {
	margin: 12px 0;
}
.sidebar-problem {
	color: var(--gray-8);
	text-transform: capitalize;
}
.sidebar-problem {
	margin-bottom: 2px;
	cursor: pointer;
}
.sidebar-problem:hover {
	color: #1890ff;
	transition: all 300ms linear;
}
.sidebar-problem:hover .sidebar-hover-icon {
	color: #1890ff;
}
.sidebar-differential-item:hover .anticon {
	font-size: 18px !important;
}
.sidebar-differential-name {
	text-transform: capitalize;
}
.sidebar-differential-item {
	cursor: pointer;
}
.sidebar-differential-item-popup-problem {
	text-transform: capitalize;
	font-weight: bold;
	cursor: pointer;
}
.sidebar-differential-item-popup-problem:hover {
	text-decoration: underline;
}
.sidebar-differential-item:hover {
	font-weight: bold;
}
.diff-item-detail {
	text-transform: capitalize;
}
.diff-item-confirm-button {
	border-color: green;
	background: green;
}
.diff-item-confirm-button:hover {
	border-color: #34ac34;
	background: #34ac34;
}
.diff-item-confirm-button.disabled,
.diff-item-confirm-button.disabled:hover {
	background: initial;
}
.upcoming-action-name {
	text-transform: capitalize;
}
.ant-card-head-title {
	padding: 8px 0;
}
.ant-card-body {
	padding: 18px;
}
.ant-card-head {
	padding: 0 18px;
	min-height: 36px;
}
.ant-drawer-bottom {
	max-width: 484px;
	max-height: 0;
	min-height: 0;
	transition: all 0.05s ease-out;
}
.ant-drawer-bottom.open {
	transition: all 0.05s ease-out;
}
.ant-drawer-bottom.mini {
	max-height: 60px;
	min-height: 60px;
	transition: all 0.05s ease-out;
	overflow: hidden;
}
.ant-drawer-bottom .ant-drawer-header {
	cursor: pointer;
}
.ant-drawer-bottom .ant-drawer-body {
	overflow-y: auto;
	transition: all 0.05s ease-out;
	padding-top: 0px;
}
.content-wrapper {
	overflow: hidden;
}
.ant-drawer-bottom .ant-drawer-content-wrapper {
	height: auto;
	max-height: 700px;
	overflow: auto;
}
.ant-drawer-bottom.mini .ant-drawer-content-wrapper {
	height: 100%;
}
.ant-drawer-bottom.mini .ant-drawer-body,
.ant-drawer-bottom.mini .ant-drawer-content {
	overflow: hidden;
	transition: all 0.05s ease-out;
}
.ant-drawer-bottom.mini .ant-drawer-body {
	max-height: 0px;
	min-height: 0px;
	transition: all 0.05s ease-out;
}
.ant-drawer-bottom.ant-drawer-open.no-mask.drawer-0 {
	left: auto;
	right: 24px;
}
.ant-drawer-bottom.ant-drawer-open.no-mask.drawer-1 {
	left: auto;
	right: calc(484px + 24px + 20px);
}
.ant-drawer-bottom.ant-drawer-open.no-mask.drawer-2 {
	left: auto;
	right: 24px;
	bottom: 61px;
	border-bottom: 1px solid grey;
}
.ant-drawer-bottom.ant-drawer-open.no-mask.drawer-3 {
	left: auto;
	right: calc(484px + 24px + 20px);
	bottom: 61px;
	border-bottom: 1px solid grey;
}

.complaint-modal .ant-modal-body {
	padding-top: 8px;
}
.ant-modal {
	transition: width 0s 2s;
}
/* 
** Overview tab chart/graph stuff
*/
div.tooltip {
	position: absolute;
	text-align: center;
	width: 46px;
	height: 20px;
	padding: 4px;
	font: 12px sans-serif;
	background: lightsteelblue;
	border: 0px;
	border-radius: 8px;
	pointer-events: none;
}
.green-bar {
	fill: #73c64c;
}
.dark-red-bar {
	fill: #f04343;
}
.light-red-bar {
	fill: rgba(255, 131, 100, 0.6);
}
.steel-blue-bar {
	fill: steelblue;
}
.discharge-medication-row-excluded.parent,
.discharge-medication-row-excluded .discharge-tree-form-item {
	color: rgba(0, 0, 0, 0.4);
	background: rgba(0, 0, 0, 0.03);
}
.discharge-medication-row-excluded .ant-form-item-control-input-content {
	color: rgba(0, 0, 0, 0.4);
}
.discharge-medication-row-excluded.parent:hover td {
	/* TODO there is prob better way of overriding ANTD than important... */
	background: rgba(0, 0, 0, 0.03) !important;
}

.discharge-modal-section-title {
	font-size: 16px;
	font-weight: var(--font-medium);
}

.recheck-save-disabled {
	pointer-events: none;
	color: rgba(0, 0, 0, 0.4);
}

.open-chat-button {
	display: flex;
	align-items: center;
	justify-content: center;
}

.closed-visit-message {
	padding: 8px 24px;
	color: var(--gray-9);
	background-color: var(--gold-1);
}

.close-chart-dropdown button {
	color: #fff;
	background-color: var(--veg-red);
	border-color: var(--gray-5);
}

.close-chart-dropdown button:hover,
.close-chart-dropdown button:focus {
	color: #fff;
	background-color: var(--veg-red);
	border-color: var(--gray-5);
}

.visit-status-selector ul {
	height: fit-content;
	width: 172px;
}

.sidebar-visit-status .ant-input.ant-cascader-input {
	padding: 0;
}

@media only screen and (max-width: 1200px) {
	.content-wrapper {
		margin-left: 60px;
	}
}

@media screen and (max-height: 800px) {
	.ant-drawer-content-wrapper {
		max-height: 500px;
	}
}

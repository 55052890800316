.whiteboard__status-section-title {
    color: #fff;
    font-weight: var(--font-semi-bold);
    text-align: center;
    text-transform: uppercase;
    font-size: 1rem;
    padding: var(--spacing-sm);
    margin: 0;
    margin-bottom: var(--spacing-xs);
    position: sticky;
    top: 140px;
    z-index: 1;
}

.whiteboard__status-section--waiting-spike-3 .whiteboard__status-section-title {
    background-color: var(--gray-8);
}

.whiteboard__status-section--assessment .whiteboard__status-section-title {
    background-color: var(--veg-tint-blue);
}

.whiteboard__status-section--plan-and-consent .whiteboard__status-section-title {
    background-color: var(--veg-secondary-plum);
}

.whiteboard__status-section--treatment .whiteboard__status-section-title {
    background-color: var(--veg-secondary-blue);
}

.whiteboard__status-section--tgh-review .whiteboard__status-section-title {
    background-color: var(--veg-secondary-teal);
}

.whiteboard__status-section--checkout .whiteboard__status-section-title {
    background-color: var(--veg-secondary-lime);
}

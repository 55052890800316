.comment-list li {
    font-size: 1rem;
    margin-bottom: 10px;
}

.comment-list li .ant-card-body {
    padding: 0;
}

.comment-list li .note-content {
    padding: var(--spacing-md);
    color: var(--gray-8);
    font-size: 14px;
}

.treatment-sheet-metadata {
    display: flex;
    align-items: center;
}

.treatment-sheet-metadata .note-details {
    display: flex;
    flex-direction: column;
    margin-left: 8px;
    font-size: 0.9rem;
    font-weight: var(--font-regular);
}

.treatment-sheet-metadata .note-details .ant-typography {
    font-size: 16px;
    font-weight: var(--font-medium);
    color: var(--gray-9);
}

.note-details__separator {
    color: var(--gray-4);
}

.note-details__time {
    color: var(--gray-8);
}

.notes-file-section {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: var(--spacing-md);
    padding-top: 0;
}
.notes-file-icon {
    font-size: 20px;
    color: #8c8c8c;
}
.notes-file-name {
    margin-left: 0.5rem;
    max-width: 500px;
}

.comment-list .treatment-sheet-metadata .title {
    margin-right: 1rem;
    font-size: 2rem;
}

.progress-note-filter-button {
    margin-bottom: 4px;
}

.progress-note-filter-button button {
    width: 100%;
}

.note__submit-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid var(--gray-4);
    margin: var(--spacing-lg) calc(var(--spacing-lg) * -1) calc(var(--spacing-md) - var(--spacing-lg));
    padding: var(--spacing-md) var(--spacing-lg) 0;
    color: var(--gray-7);
}

.note__submit-container .ant-avatar {
    margin-right: var(--spacing-sm);
    background-color: var(--veg-tint-lime);
}

.note__submit-container .ant-btn {
    margin-left: auto;
}

.__fade-transition-mixin {
	transition: all linear 250ms;
}
.overviewTab {
    background: #FFF;
}

.rowAlert {
	color: var(--veg-red);
	font-weight: 300;
}
.cardTitleSection {
	display: flex;
	flex-wrap: nowrap;
	justify-content: space-between;
	align-items: center;
}
.pendingButtonApproved {
	
	background: rgb(45, 187, 27);
}
.pendingButtonPending {
	background: rgb(255, 205, 0);
}

.billingInvoiceTotal {
	font-weight: var(--font-medium);
	font-size: 16px;
	line-height: 24px;
	margin-bottom: 20px;
}

.billingInvoiceTotalValue {
	font-size: 20px;
	line-height: 28px;
}

.billingPaymentsEstimates {
	font-weight: 400;
	font-size: 14px;
	line-height: 22px;
	margin-bottom: 10px;

}

.OverviewCard {
    border: 1px solid var(--gray-4);
    border-radius: 2px;
}

.ModalTitle {
    font-size: 20px;
    font-weight: var(--font-medium);
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
}

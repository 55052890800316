.custom-collapse.ant-collapse > .ant-collapse-item > .ant-collapse-header {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.33333333em;
    padding-left: 1.2em;
    padding-right: 0;
}

.custom-collapse.ant-collapse > .ant-collapse-item > .ant-collapse-header span {
    left: 0;
    top: 0.55em;
}

.custom-collapse.ant-collapse > .ant-collapse-item > .ant-collapse-header > div {
    display: inline-block;
    background-color: #fff;
    padding-right: 0.5em;
}

.custom-collapse.ant-collapse > .ant-collapse-item > .ant-collapse-header:after {
    width: 100%;
    height: 1px;
    background-color: var(--gray-4);
    float: right;
    margin-top: -0.666666665em;
}

.custom-collapse.ant-collapse .ant-collapse-content > .ant-collapse-content-box {
    padding: 0;
}

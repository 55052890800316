.__fade-transition-mixin {
	transition: all linear 250ms;
}
.header {
	height: 56px;
	line-height: 48px;
	padding: 0;
	padding: 0 24px;
	background-color: #EDEDED;
	box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
}
.new_patient {
	font-size: 36px;
}
.new_patient_button {
	font-size: 32px;
	color: var(--veg-red);
}
.userDropdown {
	padding: 0px 16px 0px 16px;
	cursor: pointer;
	transition: all linear 250ms;
}
.notifications_container {
	display: flex;
	align-items: center;
}
.search_container {
	display: flex;
	align-items: center;
}

.hospital-search-name {
	color: var(--VEG-Secondary-Lime, #B2D235);
	font-family: Roboto;
	font-size: 12px;
	font-style: normal;
	font-weight: 600;
	line-height: 22px;
	text-transform: uppercase;
}

.patient-search-name {
	color: var(--VEG-Black, #262626);
	font-family: SF Pro Text;
	font-size: 14px;
	font-style: normal;
	font-weight: 600;
	line-height: 22px;
	margin-bottom: 8px;
}

.no-result-found {
	color: var(--VEG-Black, #262626);
	font-family: SF Pro Text;
	font-size: 14px;
	font-style: normal;
	font-weight: 600;
	line-height: 22px;
}

.patient-search-name:hover {
	color: var(--VEG-Secondary-Blue, #143980);
}

.owner-search-name {
	color: var(--Gray-gray-9, #262626);
	font-family: Roboto;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 22px;
	margin-bottom: 8px;
}

.owner-search-name:hover {
	color: var(--VEG-Secondary-Blue, #143980);
}

.hospital_title {
	display: block;
	font-weight: 400;
	font-size: 20px;
	line-height: 1.2;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

.patient-search-input {
	width: 156px;
}

@media only screen and (max-width: 1200px) {
	.hospital_title {
		margin-left: 60px;
	}
}

@media only screen and (max-width: 768px) {
	.hospital_title {
		font-size: 16px;
		max-width: 100%;
	}
	
	.patient-search-input {
		width: 100px;
	}
}

@media only screen and (max-width: 600px) {
	.notifications_container,
	.user_name {
		display: none;
	}

	.userDropdown {
		padding: 0;
	}
}

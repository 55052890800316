.transfer-estimate-orders__title {
    display: flex;
    gap: var(--spacing-sm);
    margin-bottom: var(--spacing-md);
    font-size: 20px;
}

.transfer-estimate-orders__title :first-child {
    border-right: 1px solid var(--gray-7);
    padding-right: var(--spacing-sm);
    text-transform: uppercase;
}

.transfer-estimate-orders--no-weight {
    color: var(--veg-red);
}

.transfer-estimate-orders__table tr {
    display: grid;
    grid-template-columns: 27fr 21.5fr 16fr 16fr 19.5fr;
    column-gap: var(--spacing-sm);
    align-items: center;
}

.transfer-estimate-orders__table thead {
    background-color: var(--gray-2);
    font-weight: var(--font-semi-bold);
}

.transfer-estimate-orders__table thead th {
    padding: var(--spacing-sm);
}

.transfer-estimate-orders__table tbody tr {
    padding: var(--spacing-sm);
    grid-template-rows: minmax(53px, max-content);
    border: 1px solid var(--gray-4);
    border-bottom: none;
}

.transfer-estimate-orders__table tbody tr:last-child {
    border-bottom: 1px solid var(--gray-4);
}

.transfer-estimate-orders__table .ant-picker {
    width: 100%;
}

.transfer-estimate-orders__transfer-options {
    display: inline-flex;
    align-items: center;
    column-gap: var(--spacing-sm);
}

.transfer-estimate-orders__order-name {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding-right: var(--spacing-lg);
    position: relative;
}

.transfer-estimate-orders__medication-alert {
    position: absolute;
    right: 0;
    top: 50%;
}

.transfer-estimate-orders__table .ant-form-item {
    padding: 0;
}

.transfer-estimate-orders__table .medication-dose-alert-section {
    grid-column: 2 / 6;
    padding-right: var(--spacing-md);
    min-height: auto;
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    color: #fff !important;
    background: var(--antd-blue) !important;
    border-color: var(--antd-blue) !important;
}

/* Mobile */

.transfer-estimate-orders__mobile-header {
    display: none;
}

@media only screen and (max-width: 600px) {
    .transfer-estimate-orders__table tr {
        grid-template-columns: 1fr;
    }

    .transfer-estimate-orders__table thead {
        display: none;
    }

    .transfer-estimate-orders__table tbody tr {
        border: none;
        border-bottom: 1px solid var(--gray-4);
    }

    .transfer-estimate-orders__table tbody td,
    .transfer-estimate-orders__mobile-header {
        margin-bottom: var(--spacing-sm);
    }

    .transfer-estimate-orders__medication-alert {
        display: none;
    }

    .transfer-estimate-orders__order-name {
        padding: 0;
    }

    .transfer-estimate-orders__mobile-header {
        display: block;
    }

    .transfer-estimate-orders__table .medication-dose-alert-section {
        grid-column: 1;
    }
}

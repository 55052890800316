.billing-buttons .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    z-index: 1;
    color: #1890ff;
    background: #fff;
    border-color: #1890ff;
}
.billing-buttons .ant-radio-button-checked {
    color: #1890ff;
    border-color: #1890ff;
}
.billing-buttons .ant-radio-button-wrapper:not(:first-child)::before {
    color: #1890ff;
    border-color: #1890ff;
}

.billing-buttons .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
	color: #1890ff;
	border-color: #1890ff;
}

.billing-buttons  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
	color: #1890ff;
	border-color: #1890ff;
}

.billing-buttons  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child {
	color: #1890ff;
	border-color: #1890ff;
	border-right-color: #1890ff;
}

.billing-buttons  .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
	color: #1890ff;
	border-color: #1890ff;
	border-right-color: #1890ff;
}

.billing-buttons  .ant-radio-button-wrapper:not(:first-child)::before {
	color: #1890ff;
	border-color: #1890ff;
}

.billing-buttons .ant-radio-button-wrapper:first-child {
	border-radius: 0;
}

.billing-buttons .ant-radio-button-wrapper {
    border-width: 1px;
    z-index: 1000;
}

.billing-buttons .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background-color: #1890ff;
}

.billing-buttons .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
	background-color: #1890ff;
}

.vet-button {
    margin-right: 10px;
}

.billing-currency-display {
	font-size: 20px;
	font-weight: var(--font-semi-bold);
	margin-left: 0.2em;
}
